import { Component } from '@angular/core';
import { AuthService } from "../../auth/auth.service";

@Component({
    selector: 'app-main-header-account',
    templateUrl: './main-header-account.component.html',
    styleUrl: './main-header-account.component.scss',
    standalone: false
})
export class MainHeaderAccountComponent {
  constructor(public service: AuthService) {
  }

  ngOnInit(){

  }

  logout() {
    this.service.logout()
  }


  login() {
    this.service.login()
  }
}
