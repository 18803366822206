import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "./material/material.module";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {AuthModule} from "./auth/auth.module";
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";
import {ErrorModule} from "./error/error.module";
import {MainMenuModule} from "./component/main-menu/main-menu.module";
import {NgxEchartsDirective, provideEchartsCore} from "ngx-echarts";
import {CommerceModule} from "./commerce/commerce.module";
import {
  NgxGoogleAnalyticsModule,
  provideGoogleAnalytics,
  provideGoogleAnalyticsRouter
} from "@hakimio/ngx-google-analytics";
import {environment} from "../environments/environment";
import {provideHotToastConfig} from "@ngxpert/hot-toast";
import {HomeModule} from "./home/home.module";
import {MatIconModule} from "@angular/material/icon";

import * as echarts from 'echarts/core';
import {
  CalendarComponent,
  DatasetComponent,
  DataZoomComponent,
  GraphicComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  VisualMapComponent
} from 'echarts/components';
import {BarChart, GraphChart, HeatmapChart, LineChart, PieChart, TreeChart} from 'echarts/charts';
import {LabelLayout, UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';

import 'echarts/theme/macarons.js';

registerLocaleData(localeDe);

echarts.use([
  // charts ...
  BarChart,
  LineChart,
  HeatmapChart,
  PieChart,
  GraphChart,
  TreeChart,
  // components ...
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  DataZoomComponent,
  VisualMapComponent,
  GraphicComponent,
  CalendarComponent,
  // features ...
  LabelLayout,
  UniversalTransition,
  // renderers ...
  CanvasRenderer
]);


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
            BrowserAnimationsModule,
            ReactiveFormsModule,
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }
            }),
            AppRoutingModule,
    // custom modules
            HomeModule,
            AuthModule,
            ErrorModule,
            MaterialModule,
            MainMenuModule,
            CommerceModule,
            NgxGoogleAnalyticsModule,
            NgxEchartsDirective,
            MatIconModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    provideGoogleAnalytics(environment.googleAnalyticsId),
    provideGoogleAnalyticsRouter(),
    provideHotToastConfig(),
    provideEchartsCore({echarts}),
    provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule {
}
