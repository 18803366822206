import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { Component } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@Component({
    selector: 'app-loading-dialog',
    templateUrl: 'loading-dialog.component.html',
    imports: [MatDialogModule, MatButtonModule, MatProgressBarModule]
})
export class LoadingDialogComponent {

}
