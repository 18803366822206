import { Component, computed, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    standalone: false
})
export class HomeComponent {
  collapsed = signal(false)
  isMobile: boolean = false
  sidenavWidth = computed(() => (this.collapsed() ? '85px' : '300px'));


  constructor(private breakpoint: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpoint.observe([Breakpoints.Handset])
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches
      })
  }

}
